<template>
  <div class="shopList">
    <div v-loading="loading"></div>
    <div class="shopList-text0 flex7">
      我们将于每月最后一周统一邮寄实体礼品，请至「 个人中心  - 兑换记录 」 查看礼品兑换进度。
    </div>
    <div class="shopList-details">
      <div class="shop-search">
        <h1>全部商品</h1>
        <div class="input-search">
          <el-input v-model.trim="formSearch.productName" type="text" @keyup.enter.native="Search" placeholder="请输入您要查找的商品名称"></el-input>
          <i class="el-icon-search cur" @click="Search"></i>
        </div>
        <div class="sort-type tr flex">
          <div class="sxbtn flex0 cur" @click="Sort(0)">
            <span :class="IsGold ? 'violet' : ''">金币</span>
            <div>
              <div class="ic-san ic-san-top" :class="(IsGold && !goldstate) ? 'ic-san-top-active' : ''"></div>
              <div class="ic-san ic-san-bottom" :class="(IsGold && goldstate) ? 'ic-san-bottom-active' : ''"></div>
            </div>
          </div>
          <div class="sxbtn flex0 cur" @click="Sort(1)">
            <span :class="IsHot ? 'violet' : ''">热度</span>
            <div>
              <div class="ic-san ic-san-top" :class="(IsHot && !hotstate) ? 'ic-san-top-active' : ''"></div>
              <div class="ic-san ic-san-bottom" :class="(IsHot && hotstate) ? 'ic-san-bottom-active' : ''"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="shop-lists">
        <div v-for="item in ShopLists.data" :key="item.id" class="shop-lists-item flex">
          <div @click="changes(item.id)" class="shop-lists-item-img cur">
            <img :src="item.img">
            <div v-if="item.isDiscount" class="shop-lists-item-Discount tc co-fff">{{Discount(item.oldPrice,item.price)}}</div>
          </div>
          <div class="shop-lists-item-d flex8">
            <div class="shop-lists-item-d-l">
              <h1 @click="changes(item.id)" class="cur violet">{{ item.title }}</h1>
              <div v-if="item.isDiscount" class="inline">
                <div class="time-limit-price flex2">
                  <div class="time-limit-price-text co-fff">限时价</div>
                  <img class="time-limit-price-img" src="../../assets/PC/shop/gold.png" alt="">
                  <b class="violet mr">{{item.price}}</b> 
                </div>
                <div class="original-cost co-999">原价: {{ item.oldPrice }} 金币</div>
              </div>
              <div v-else>
                <p class="shop-lists-item-oldprice"><b class="co-961645"> {{ item.price }} </b> 金币</p>
              </div>
              <p class="change-num"><span class="co-999">已兑换：{{item.num - item.residueNum}}/{{item.num}}</span></p>
            </div>
            <div class="shop-lists-item-d-r">
              <el-button v-if="item.isDiscount" @click="changes(item.id)" type="warning" :size="clientWidth > 750 ? '' : 'medium' " class="Uschange">我要兑换</el-button>
              <el-button v-else @click="changes(item.id)" type="info" :size="clientWidth > 750 ? '' : 'medium' " class="Uschange">我要兑换</el-button>
            </div>
            <div v-if="item.isDiscount" class="shop-lists-item-d-Discount">
              <img src="../../assets/PC/shop/qiang.png" alt="">
            </div>
          </div>
        </div>
        <div class="page tr">
          <el-pagination
            @current-change="handleCurrentChange"
            :total="ShopLists.recordCount"
            :page-size="ShopLists.pageSize"
            :current-page="ShopLists.pageIndex"
            background
            layout="pager, next"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
        <div v-if="IsloadList && clientWidth < 750" class="loadList flex0">
          <div class="loadList0" @click="loadlist">加载更多</div>
        </div>
        <div v-if="clientWidth < 750 && !IsloadList && ShopLists.data.length > 0" class="tc notDetail">
          <div>
            没有更多内容了~ 
            <span class="violet" @click="scrollTop">返回顶部</span>
          </div>
        </div>
      </div>
      <div v-if="Isdata" class="noData">
        <img src="@/assets/PC/profile/listNone1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import * as Shop from "@/api/Shop.js";
import { dataState } from "@/api/all.js";
import {GetWxShares } from '@/utils/common'


export default {
  name: "ShopList",
  data() {
    return {
      loading: true,         // !loading
      IsloadList: false,     // !加载更多显示与隐藏
      IsGold: false,         // !是否选中金币排序
      goldstate: true,       // !金币默认排序
      IsHot: false,          // !是否选中热度排序
      hotstate: true,        // !热度默认排序
      formSearch:{
        productName: '',     // !搜索框
        page: 1,             // !页数
        limit: 5,            // !每页数量
        orderbyType: 0,      // !金币
        Orderanddesc: true,  // !排序
      },
      ShopLists: {
        data: [],
        recordCount: 0,
      },                     // !商品列表 
      Isdata: false,         // !暂无数据              
    }
  },
  mounted() {
    const share = {
      title: '剑盟商城 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    // 商品列表
    this.GetInit();
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "ShopList",
      "relationId": ''
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    });
  },
  methods: {
    // !计算折扣
    Discount(oldprice, price) {
      const arr1 = ['零','一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let k = (price / oldprice + '').substr(0, 4);
      k = (k * 1).toFixed(1);
      let priceArr = '限时';
      if(k < 0.1) {
        priceArr += '一';
      } else if(k == 1){
        priceArr += '九';
      } else {
        priceArr += arr1[ k[2] ];
      }
      priceArr += '折';
      return priceArr ;
    },
    // !搜索商品
    Search() {
      this.loading = true;

      this.IsGold = false;
      this.goldstate = true;
      this.IsHot = false;
      this.hotstate = true;

      
      this.formSearch.page = 1;
      this.formSearch.limit = 5;
      this.formSearch.orderbyType = 0;
      this.formSearch.Orderanddesc = true;
      this.GetInit(true, true);
    },
    // !排序
    Sort(type) {
      this.loading = true;
      this.formSearch.orderbyType = type;
      this.formSearch.page = 1;

      if(type == 0) {
        this.IsHot = false;
        this.hotstate = true;

        this.goldstate = !this.goldstate;
        this.IsGold = true;
        this.formSearch.Orderanddesc = this.goldstate;
      } else if (type == 1) {
        this.IsGold = false;
        this.goldstate = true;

        this.IsHot = true;
        this.hotstate = !this.hotstate;
        this.formSearch.Orderanddesc = this.hotstate;
      }
      this.GetInit();
    },
    // !点击兑换
    changes(id) {
        const Id = {'productId': id};
        this.$router.push('/Home/ShoppingMall/ShopDetail?id=' + id);
    },
    // !初始化列表
    GetInit(Ismobile, IsSearch){
      Shop.GetList(this.formSearch).then(res => {
        if(res.status == 1){
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            }else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              if(IsSearch) {
                this.ShopLists = res;
              }else {
                this.ShopLists.data = this.ShopLists.data.concat(res.data);
                if(this.ShopLists.data.length == res.recordCount) {
                  this.IsloadList = false;
                }
              }
            } else {
              this.ShopLists = res;
            }
          } else {
            this.ShopLists = {data: [], recordCount: 0};
            this.IsloadList = false;
            this.Isdata = true;
          }
        }else{
          this.$toast(res.message)
        }
        this.loading = false;
      })
    },
    // !分页
    handleCurrentChange(currentPage) {
      this.loading = true;
      this.formSearch.page = currentPage;
      this.GetInit();
    },
    // !点击加载更多
    loadlist() {
      if(this.clientWidth < 750){
        this.loading = true;
        this.formSearch.page++;
        this.GetInit(true, false);
      }
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth
    }
  }
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .shopList {
    position: relative;
    .shopList-text0, .shop-lists-item-d {width: 100%;}
    .shopList-text0,.shopList-details {background: #f9f9f9;border-radius: 5px;box-shadow: 0 0 10px #ccc;padding: 0 30px;}

    .shopList-text0 {height: 50px;padding: 0 30px;margin-bottom: 5px;}

    .shopList-details {padding-bottom: 30px;margin-bottom: 20px;}
    .shop-search h1 {line-height: 70px;font-weight: normal;}
    .input-search {position: relative;margin-bottom: 15px;}
    .input-search i {position: absolute; right: 10px;top: 10px;font-size: 18px;color: #ccc;}
    
    .shop-lists-item {background: #fff; margin-bottom: 25px; border-radius: 5px;}
    .shop-lists-item-img {min-width: 183px; position: relative; overflow: hidden;}
    .shop-lists-item-img img{width: 183px;height: 183px;border-radius: 5px;}
    .shop-lists-item-Discount {width: 100px; position: absolute; top: 15px; right: -25px; background: linear-gradient(to right,#dc332c,#fbb45c); padding: 3px 0; transform: rotate(45deg); font-size: 12px;}

    .shop-lists-item-d {padding: 25px; position: relative;}
    .shop-lists-item-d h1 {margin-bottom: 15px; font-size: 20px; overflow: hidden;}
    .original-cost {text-decoration: line-through;}
    .time-limit-price {border: 1px #f2791d solid; border-radius: 3px; height: 21px;}
    .time-limit-price-text { font-size: 12px; background: linear-gradient(to top,#dc332c,#fbb45c); padding: 0 8px; border-radius: 2px; height: 21px; line-height: 21px; margin-left: -1px;}
    .time-limit-price-img {margin: 0 5px; width: 15px;}  
    .shop-lists-item-oldprice b {font-size: 16px;}
    .shop-lists-item-oldprice {margin-bottom: 25px;}
    .shop-lists-item-d-Discount {position: absolute; right: -5px; top: -10px;}

    .sort-type {margin-bottom: 15px; justify-content: flex-end;}
    .sxbtn span {font-size: 16px;margin-right: 2px; color: #ccc;}
    .sxbtn {margin-left: 15px;}
    .ic-san {width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; }
    .ic-san-top {border-bottom: 5px solid #ccc;}
    .ic-san-bottom {border-top: 5px solid #ccc;margin-top: 2px;}
    .ic-san-top-active {border-bottom: 5px solid #6b18a8;}
    .ic-san-bottom-active {border-top: 5px solid #6b18a8;}
  } 
  @media (min-width: 750px) {
    .original-cost {margin: 20px 0;}
  }
  @media (max-width: 750px) {
    .shopList {
      margin: 0 vw(30);margin-top: vw(-100);
      .shopList-text0 {height: vw(100);padding: 0 vw(30);}
      .shop-search h1 {line-height: vw(110);font-weight: normal;font-size: vw(36);}

      .shopList-details {padding: 0 vw(30) vw(30) vw(30);}
      .input-search i {top:vw(16);right: vw(14);font-size: vw(36);}
      .shop-lists-item {margin-bottom: vw(30); box-shadow: 0 0 vw(10) #ccc;}
      .shop-lists-item-img {min-width: vw(244);}
      .shop-lists-item-img img{width: vw(244); height: vw(244); border-radius: vw(6)}

      // .shop-lists-item-d-l {position: relative; z-index: 1;}
      .shop-lists-item-Discount {font-size: vw(24);}
      .shop-lists-item-d-Discount {top: vw(-10); right: vw(-6);}
      .shop-lists-item-d-Discount img {width: 50px;}

      .shop-lists-item-d {flex-wrap: wrap; padding: vw(10) vw(10) vw(10) vw(20)}
      .shop-lists-item-d h1 {margin-bottom: vw(0); font-size: vw(28); max-width: vw(350); white-space: nowrap; overflow: hidden;}
      .shop-lists-item-oldprice, .change-num {margin-bottom: vw(10);}
      .Uschange,.shop-lists-item-d-r {width: 100%;}
      .loadList0 {margin-bottom: 0; background: #dcdcdc;}

      .original-cost,.shop-lists-item-d .change-num span,.shop-lists-item-d .change-gold .co-999{font-size: vw(24);}
    }
  }
</style>