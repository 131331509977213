import request from '@/utils/request'

// !获取商品列表
export function GetList(params) {
  return request({
    url: '/Product/GetList',
    method: 'GET',
    params
  })
}
// !获取商品详情
export function GetProeuctInfo(params) {
  return request({
    url: '/Product/GetProeuctInfo',
    method: 'GET',
    params
  })
}
// !验证商品剩余
export function ValidateOrder(params) {
  return request({
    url: '/Product/ValidateOrder',
    method: 'GET',
    params
  })
}
// !获取省市区
export function GetAllCity() {
  return request({
    url: '/Product/GetAllCity',
    method: 'GET',
  })
}
// !获取默认地址
export function GetDefaultAddress() {
  return request({
    url: '/Product/GetDefaultAddress',
    method: 'GET',
  })
}
// !兑换商品地址
export function BuyProduct(data) {
  return request({
    url: '/Product/BuyProduct',
    method: 'post',
    data
  })
}

// !判断是否为电子商品
export function ValidateProductType(params) {
  return request({
    url: '/Product/ValidateProductType',
    method: 'get',
    params
  })
}
// !限制兑换一次
export function ValidateOnlyBuyOne(params) {
  return request({
    url: '/Product/ValidateOnlyBuyOne',
    method: 'get',
    params
  })
}




